:root {
   --primary: #3D92D0;
   --body: #E5E5E5;
   --secondary: #FFCC33;
   --gray: #979797;
   --green: #00AF50;
   --green-light: #EDFFF5;
   --red: #FF3500;
}

body {
   /* background-color: var(--body); */
}

header {
   padding: 20px 10px;
   display: flex;
   align-items: center;
}

img {
   width: 100%;
}

a,
a:hover {
   color: var(--primary);
}

.bg-white {
   background-color: white !important;
}

.bg-page {
   background-color: #fafbfe !important;
}

.text-gray {
   color: var(--gray)
}

.text-blue {
   color: var(--primary);
}

.text-red {
   color: var(--red)
}

.text-bold {
   font-weight: bold;
}

.cursor-pointer {
   cursor: pointer;
}

/* 
.text-capitalize {
   text-transform: capitalize;
} */

.app-logo {
   width: 100px;
}

.gif-loader {
   width: 130px;
   position: absolute;
   right: 0;
   top: 0;
   margin-right: -40px;
}

.middle-page {
   margin: auto;
   max-width: 500px;
   background-color: #fff;
   border-radius: 10px;
   box-shadow: 0px 0px 3px #ccc;
}

.login-m-page {
   max-width: 450px;
   margin: 10% auto 0 auto;
   padding: 5% 10px;
}

.usertype-panel {
   display: flex;
   justify-content: center;
   margin: 20px 0 25px 0;
}

.usertype-panel div {
   width: 100px;
   text-align: center;
   padding: 10px 0;
   cursor: pointer;
}

.usertype-panel div.active {
   color: var(--primary);
   border-bottom: 2px solid var(--primary);
   font-weight: bold;
}

.rg-pass-eye {
   position: absolute;
   width: 20px;
   right: 12px;
   bottom: 0;
   top: 41px;
}


.app-btn {
   width: 100%;
   background-color: var(--secondary);
   text-transform: uppercase;
   border-radius: 5px;
   border: 0;
   padding: 7px 5px;
}

/* Navigation */
.app-nav {
   list-style: none;
   list-style-type: none;
   display: flex;
   margin: 0;
}

.app-nav li {
   margin-right: 15px;
}

.app-nav li a {
   padding: 5px;
   font-size: 16px;
   color: inherit;
   text-decoration: none;
}

.app-nav li a.active {
   color: var(--primary);
   border-bottom: 2px solid var(--primary);
   font-weight: bold;
}

.app-modal {
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   justify-content: center;
   display: flex;
   align-items: center;
   background-color: rgba(0, 0, 0, 0.2)
}

.app-modal-container {
   border-radius: 5px;
   padding: 20px;
   background-color: white;
   display: inline-block;
}


/* Others */
.wallet-holder {
   border-radius: 10px;
   border: 1px solid;
   display: inline-block;
   padding: 7px 20px;
}

.wlt-div1 {
   display: flex;
}

.rq-holda img {
   width: 100%;
   max-width: 430px;
}

.job-holda {
   display: flex;
   justify-content: space-around;
   max-width: 360px;
}

.ongoing-job-holda {
   border: 1px solid var(--primary);
   border-radius: 5px;
   min-width: 162px;
   padding: 10px 5px;
}

.completed-job-holda {
   border: 1px solid var(--green);
   background-color: var(--green-light);
   border-radius: 5px;
   min-width: 162px;
   padding: 10px 5px;
}

.job-card {
   box-shadow: 0px 0px 3px #ccc;
   border-radius: 5px;
}

.job-header {
   display: flex;
   padding: 10px 15px;
}

.job-dp {
   width: 50px;
   height: 50px;
   overflow: hidden;
   border-radius: 50px;
   margin-right: 15px;
   border: 1px solid #eee;
}

.job-dp img {
   max-width: 100%;
}

.job-details {
   padding: 20px 15px;
   border-top: 1px solid #DADADA;
   border-bottom: 1px solid #DADADA;
}

.job-footer {
   display: flex;
   padding: 10px 15px;
   justify-content: space-between;
}

.job-status {
   padding: 2px 5px;
   border-radius: 2px;
   background-color: lightgrey;
}

.rq-bar-stage {
   display: flex;
   align-items: center;
   max-width: 450px;
   justify-content: space-between;
}

.rq-bar-stage div {
   width: 65px;
   height: 4px;
   border-radius: 5px;
   background-color: #C4C4C4
}

.rq-bar-stage div.active {
   background-color: var(--primary);
}

.rq-skill-cat {
   box-shadow: 0px 0px 3px #ccc;
   border-radius: 5px;
   padding: 10px;
   display: flex;
   justify-content: space-between;
}

.rq-addr-modal {
   position: absolute;
   background-color: #fff;
   left: 0;
   top: 100%;
   height: 300px;
   width: 100%;
   box-shadow: 0px 0px 3px #ccc;
   border-radius: 5px;
   margin-top: -15px;
}

.rq-addr-modal div {
   border-bottom: 1px solid #ccc;
   padding: 5px 10px;
   overflow: hidden;
}

.rq-addr-modal div:hover {
   background-color: var(--body);
   cursor: pointer;
}

.rq-skill-list-row {
   position: relative;
   top: unset;
   height: unset;
   margin-top: unset;
}

.rq-loader {
   height: 60px;
}

.rq-wrkr-dp {
   width: 30px;
   margin-right: 15px;
   border-radius: 50px;
   height: 50px;
   width: 50px;
}

/* ACCOUNT CSS */
.acc-dp {
   width: 150px;
   height: 150px;
   overflow: hidden;
   border-radius: 150px;
   margin-right: 15px;
   border: 1px solid #eee;
}

.acc-nav {
   margin-bottom: 1rem;
}

.acc-nav:hover {
   padding: 12px;
   cursor: pointer;
   text-decoration: none;
}

.rq-note {
   background-color: #FFF3CD;
   border-radius: 5px;
   padding: 10px;
   text-align: center;
}


@media (max-width:767px) {
   .rq-skill-list {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: white;
      max-width: 100%;
      left: 0;
   }
}

@media (max-width:500px) {
   .app-nav {
      display: none;
   }
}